.SubjectRow {
	color: white;
	position: relative;
}

.SubjectRow .title {
	position: absolute;
	left: 2.5%;
	bottom: 15.2vw;
}

@media screen and (max-width: 500px) {
	.SubjectRow .title {
		font-size: 0.6em;
	}
}

@media screen and (max-width: 700px) {
	.SubjectRow .title {
		bottom: 28.5vw;
	}
}

/* .SubjectRow .title:hover {
	color: #f1881c;
	cursor: pointer;
} */

.SubjectRow .scroller {
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	padding: 3vw 0;
}

.SubjectRow .scroll-button {
	position: absolute;
	height: 100%;
	width: 2vw;
	bottom: 0;
	top: 0;
	display: none;
	border: none;
	padding: 0;
	display: inline-block;
}

.SubjectRow .scroll-button.left {
	left: 0;
	z-index: 2;
	background: url("../svg/previous-page-button.svg") no-repeat center rgba(20, 20, 20, 0.7);
}

.SubjectRow .scroll-button.right {
	right: 0;
	z-index: 2;
	background: url("../svg/next-page-button.svg") no-repeat center rgba(20, 20, 20, 0.7);
}

@media (pointer: coarse) {
	/* Touch screen, so scrolling through courses can be done without buttons*/
	.SubjectRow .scroller {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.SubjectRow:hover .scroll-button {
		display: none;
	}
	.SubjectRow .scroll-button {
		display: none;
	}
	.SubjectRow .scroller > .Course:last-of-type {
		margin-right: 3%;
	}
}

/* Right-margins so the last page can consist of <4 courses 
   This is only necessary on desktop when the buttons are visible*/

@media (pointer: fine) {
	.SubjectRow .scroller > .Group:last-of-type:nth-of-type(4n + 1) {
		margin-right: 75%;
	}
	.SubjectRow .scroller > .Group:last-of-type:nth-of-type(4n + 2) {
		margin-right: 51%;
	}
	.SubjectRow .scroller > .Group:last-of-type:nth-of-type(4n + 3) {
		margin-right: 27%;
	}
	.SubjectRow .scroller > .Group:last-of-type:nth-of-type(4n) {
		margin-right: 3%;
	}
	@media screen and (max-width: 700px) {
		.SubjectRow .scroller > .Group:last-of-type:nth-of-type(2n) {
			margin-right: 3%;
		}
		.SubjectRow .scroller > .Group:last-of-type:nth-of-type(2n + 1) {
			margin-right: 52%;
		}
	}
}
