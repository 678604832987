* {
	box-sizing: border-box;
	font-family: "Calibri", "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
	margin: 0;
	width: 100%;
	height: 100%;
	background: #141414;
	font-size: 17px;
}

.App {
	margin-top: 4%;
}

.error {
	color: #d8000c;
	margin-left: 20px;
}

.codeblock {
	padding: 15px;
	color: #f7e9e9;
	border-radius: 5px;
	margin-left: 40px;
	max-width: 1000px;
	background-color: #333;
}
.codeblock p {
	font-size: 20px;
	margin-left: 5px;
}

.stack {
	font-family: "Consolas";
	padding-left: 20px;
	margin-top: 5px;
}
