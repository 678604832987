.Group {
	height: 12.7vw;
	/* ratio ~1.8*/
	width: 23vw;
	position: relative;
	display: inline-block;
	margin-right: 1%;
	transition: transform 0s;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.5);
}

.Group.large {
	height: 300px;
	width: 200px;
}

.Group .arrow {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	bottom: -1vw;
	height: 0;
	width: 0;
	border-left: 1.5vw solid transparent;
	border-right: 1.5vw solid transparent;
	border-top: 1.5vw solid #f1881c;
}

.Group .text {
	font-family: "Expletus Sans";
	position: absolute;
	bottom: 2%;
	left: 2%;
	margin: 0;
	width: 96%;
	white-space: normal;
	word-wrap: break-word;
	max-height: 100%;
	overflow: hidden;
}

@media screen and (max-width: 1200px) {
	.Group .text {
		width: 100%;
		margin-bottom: 0;
		margin-left: 0;
		font-size: 1em;
	}
}

@media screen and (max-width: 700px) {
	/* Two Groups per page for mobile */
	.Group {
		width: 47vw;
		height: 26vw;
		margin-right: 1%;
	}
}

.Group .q-logo {
	position: absolute;
	font-size: 21pt;
	top: 3%;
	left: 2%;
	width: 6%;
}

.Group:first-child {
	/* So the left most Group doesn't scale out of screen */
	margin-left: 2.5%;
	transform-origin: left;
}

.Group:hover {
	transform: scale(1.3);
	transition: transform 0.35s;
	z-index: 3;
}

@media (pointer: fine) {
	.Group:nth-of-type(4n + 1) {
		transform-origin: left;
	}
	/* To avoid getting in the way of the page buttons */
	.Group:nth-of-type(4n) {
		transform-origin: right;
	}
	@media screen and (max-width: 700px) {
		.Group:nth-of-type(2n) {
			transform-origin: right;
		}
		.Group:nth-of-type(2n + 1) {
			transform-origin: left;
		}
	}
}

.Group.large:hover {
	transform: scale(1.33);
	transition: transform 0.35s;
}

.Group.selected {
	transform: scale(1.3);
	z-index: 2;
}

.Group.purple:after {
	display: block;
	background-image: linear-gradient(45deg, #460eaf 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}

.Group.pink:after {
	display: block;
	background-image: linear-gradient(45deg, #fc22fc 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}

.Group.blue:after {
	display: block;
	background-image: linear-gradient(45deg, #00add3 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}

.Group.orange:after {
	display: block;
	background-image: linear-gradient(45deg, #f1881c 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}

.Group.red:after {
	display: block;
	background-image: linear-gradient(45deg, #e20b12 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}

.Group.green:after {
	display: block;
	background-image: linear-gradient(45deg, #00fe81 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}

.Group.yellow:after {
	display: block;
	background-image: linear-gradient(45deg, #ffc000 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}
