@font-face {
	font-family: "Calibri";
	src: url("Calibri.eot");
	src:
		local("Calibri"),
		url("Calibri.eot?#iefix") format("embedded-opentype"),
		url("Calibri.woff2") format("woff2"),
		url("Calibri.woff") format("woff"),
		url("Calibri.ttf") format("truetype"),
		url("Calibri.svg#Calibri") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Expletus Sans";
	src: url("ExpletusSans-Bold.eot");
	src:
		local("Expletus Sans Bold"),
		local("ExpletusSans-Bold"),
		url("ExpletusSans-Bold.eot?#iefix") format("embedded-opentype"),
		url("ExpletusSans-Bold.woff2") format("woff2"),
		url("ExpletusSans-Bold.woff") format("woff"),
		url("ExpletusSans-Bold.ttf") format("truetype"),
		url("ExpletusSans-Bold.svg#ExpletusSans-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Calibri";
	src: url("Calibri-Bold.eot");
	src:
		local("Calibri Bold"),
		local("Calibri-Bold"),
		url("Calibri-Bold.eot?#iefix") format("embedded-opentype"),
		url("Calibri-Bold.woff2") format("woff2"),
		url("Calibri-Bold.woff") format("woff"),
		url("Calibri-Bold.ttf") format("truetype"),
		url("Calibri-Bold.svg#Calibri-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Calibri";
	src: url("Calibri-Light.eot");
	src:
		local("Calibri Light"),
		local("Calibri-Light"),
		url("Calibri-Light.eot?#iefix") format("embedded-opentype"),
		url("Calibri-Light.woff2") format("woff2"),
		url("Calibri-Light.woff") format("woff"),
		url("Calibri-Light.ttf") format("truetype"),
		url("Calibri-Light.svg#Calibri-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
