.GroupInfo {
	width: 96vw;
	position: relative;
	text-align: center;
	height: 90vh;
	color: white;
	margin: 0 2vw 4vw 2vw;
}

.GroupInfo .text-and-image {
	height: 90%;
	background-color: #1f1f1f;
	display: flex;
}

.GroupInfo .info-text {
	text-align: left;
	margin-top: 2vw;
	margin-left: 2vw;
	flex: 1.3 1 0;
	overflow-y: auto;
	overflow-x: hidden;
	word-wrap: break-word;
}

@media screen and (max-width: 1100px) {
	.GroupInfo .info-text {
		font-size: 0.7em;
	}
}

@media screen and (max-width: 800px) {
	.GroupInfo .info-text {
		font-size: 0.4em;
	}
}

.GroupInfo .info-text .title {
	font-family: "Expletus Sans";
	margin: 0 0 10px 0;
}

.GroupInfo .info-text .short-description {
	margin-top: 0.2em;
}

.GroupInfo .info-text .period {
	color: #f1881c;
	height: 2em;
	line-height: 2em;
}

.GroupInfo .info-text .period .square {
	width: 2em;
	height: 2em;
	display: inline-block;
	vertical-align: middle;
	background: #f1881c;
}

.GroupInfo .info-text .period .number {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.2em;
	margin: 0 0 0 0.5em;
}

.GroupInfo .info-text .description-title {
	font-weight: bold;
}

.GroupInfo .info-text .enroll-button {
	border: none;
	background: #f1881c;
	color: white;
	padding: 10px;
	font-size: inherit;
	font-weight: 500;
}

.GroupInfo .info-text .enroll-button:hover {
	cursor: pointer;
	color: lightgrey;
}

.GroupInfo .image {
	flex: 2;
}

.GroupInfo .image:after {
	display: block;
	background-image: linear-gradient(to right, #1f1f1f 10%, transparent 80%);
	height: 100%;
	width: 100%;
	content: "";
}

.GroupInfo .close-button {
	background: url("../svg/close-button.svg");
	position: absolute;
	right: 1vw;
	top: 1vw;
	width: 3vw;
	height: 3vw;
	padding: 0;
	border: none;
}

@media screen and (max-width: 500px) {
	.GroupInfo .close-button {
		width: 5vw;
		height: 5vw;
	}
}

.GroupInfo .close-button:hover {
	filter: grayscale(30%);
	cursor: pointer;
}

.GroupInfo .extra-info {
	display: flex;
	background-color: black;
}

.GroupInfo .extra-info-text {
	margin: 2em 2em;
}
